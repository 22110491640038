:root {
  --sjs-primary-backcolor: #052268;
  --content-width: 672px;
  --font-family: 'Poppins', sans-serif;
  --sjs-primary-forecolor: #FFF;
  --sjs-primary-backcolor-dark: #052268;
  --color-primary: #ff4a12;
}

@media (max-width: 767px) {
  .smartplayer-call-action--link {
    padding: 1em 9px !important;
    width: 100% !important;
  }
}