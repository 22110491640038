:root {
  --sjs-primary-backcolor: #052268;
  --content-width: 672px;
  --font-family: 'Poppins', sans-serif;
  --sjs-primary-forecolor: #FFF;
  --sjs-primary-backcolor-dark: #052268;
  --color-primary: #ff4a12;
}

#root .sd-progress {
  height: 10px;
}

#root .sd-root-modern {
  background-color: transparent;
  margin-top: 35px;
  position: relative;
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
}

.sd-body.sd-body--static {
  max-width: var(--content-width);
}

.sd-progress {
  max-width: var(--content-width);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 10px;
  overflow-x: hidden;
  position: relative;
}

.sd-progress__bar {
  transition: .4s;
  background-color: var(--color-primary);
}

.sd-page {
  padding: 0 16px;
}

#sv-nav-prev input,
#sv-nav-next input,
#sv-nav-start input,
#sv-nav-complete input {
  padding: 0 20px;
  flex: 1 1 auto;
  height: 53px;
  width: 100%;
}

#sv-nav-prev input {
  background-color: #f1f1f1;
  box-shadow: none;
  font-weight: normal;
  color: #161616;
}
#sv-nav-prev input:hover {
  background-color: #ddd;
}


#sv-nav-start input,
#sv-nav-next input,
#sv-nav-complete input {
  box-shadow: 0 0 0 2px var(--color-primary);
  background: var(--color-primary);
  color: #fff;
  font-weight: 600;
}

#sv-nav-next input:hover,
#sv-nav-complete input:hover {
  color: #fff;
  background-color: #ff6131;
  box-shadow: 0 0 0 2px #ff6131;
}

.sd-item__control:focus+.sd-item__decorator {
  background-color: none;
}

.sd-selectbase {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sd-item.sd-radio {
  padding: 0;
  background: #f3f3f3;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: .2s;
}

.sd-item.sd-radio:hover {
  transition: .3s;
  background-color: #e9e9e9;
}

.sd-btn {
  transition: .2s ease-in-out!important;
  background: #fff;
}

.sd-item.sd-radio .sd-selectbase__label {
  width: 100%;
  cursor: pointer;
  padding: 14px 15px;
}

.sd-item__decorator.sd-radio__decorator {
  display: none;
}

.sd-item.sd-radio.sd-radio--checked {
  background-color: var(--sjs-primary-backcolor);
  color: #FFF;
}

.sd-item.sd-radio.sd-radio--checked .sd-item__control-label {
  color: #FFF;
}

.sd-item.sd-radio .sd-item__control-label {
  font-weight: 600;
}

.sd-question__required-text {
  display: none;
}

.sd-element--with-frame {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin-bottom: 30px;
}

.sd-element__title {
  margin-bottom: 10px;
  text-align: center;
}

.sd-element__title .sv-string-viewer {
  font: 600 22px/1.3 var(--font-family);
  color: #3e3f4d;
  text-align: center;
  letter-spacing: -0.6px;
}

.sd-title .sv-title-actions .sv-title-actions__title {
  max-width: 100%;
  width: 100%;
}

.sd-element__header .sd-description {
  text-align: center;
  margin-bottom: 20px;
  color: #656260;
  font-size: 17px;
}

.sd-element--with-frame>.sd-question__erbox--outside-question {
  max-width: calc(var(--content-width) - 32px);
  margin: 0px auto 50px;
  padding: 14px;
  top: 10px;
  max-width: 100%;
  margin-bottom: 0;
}


.sd-root-modern .sd-container-modern {
  margin-bottom: 0;
  min-height: 530px;
}

.sd-body.sd-body--static .sd-body__page {
  padding-top: 0;
}

.sd-row__question, .sd-body.sd-body--static .sd-body__timer, .sd-body.sd-body--static .sd-body__navigation, .sd-body.sd-body--static .sd-body__page {
  padding: 0;
}

.sd-title .sv-title-actions {
  width: auto;
}

/* .sd-element__title {
  text-align: left;
  margin-bottom: 0;
}
.sd-element__title span.sv-string-viewer {
  font-size: 14px;
} */

.sd-page.sd-body__page > .sd-row.sd-page__row {
  margin-top: 10px;
}

.sd-element--with-frame {
  margin-bottom: 10px;
}

.sd-question__header--location-top {
  padding-bottom: 10px;
}

.sd-action-bar {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.sd-action-bar .sv-action {
  flex-grow: 0!important;
  width: 180px;
  max-width: 47%;
}
div#sv-nav-start {
  max-width: 100%;
  width: 100%;
}

.sv-title-actions .sd-action-bar { 
  display: none;
}

.sd-image-grid {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto 30px;
}

.sd-image-grid > h1 {
  font: 600 22px/1.3 var(--font-family);
  color: #3e3f4d;
  text-align: center;
}

.sd-image-grid > img {
  max-width: 70%;
}
.sd-image-grid img {
  border-radius: 10px;
  max-height: 600px;
  box-shadow: 1px 10px 12px rgba(0,0,0,0.09);
}
@media (max-height: 900px) {
  .sd-image-grid img {
    max-width: 100%;
    max-height: 500px;
  }
}


@media (max-height: 1400px) and (max-width: 1200px){
  .sd-body.sd-body--static .sd-body__navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 999;
    box-shadow: 1px -2px 5px rgba(0,0,0,.07);
    padding: 12px;
    justify-content: center;
    /* gap: 30px */
  }
}

@media (max-height: 1400px) {
  div#sv-nav-prev,
  div#sv-nav-next {
    width: 45%;
    max-width: calc(670px / 2);
  }

  .sd-root-modern {
    padding-bottom: 20px;
  }

  .sd-root-modern .sd-container-modern {
    margin-bottom: 0;
    min-height: auto;
  }

  .sd-action-bar .sv-action {
    flex-grow: 1!important;
    min-width: auto;
  }
}

.sd-row:nth-child(2) .sd-title span {
  font-size: 17px;
  font-weight: 400;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff4a12;;
  border-radius: 50%;
}

input[type="text"],
.sd-input[type="text"] {
  background: #fff;
  box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.05);
  border: 1px solid #e9e9e9;
  height: 57px;
}

.sd-text__content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  margin: 0 auto;
}


/* Splide */
.splide__arrow {
  transition: .4s;
}
.splide__arrow--prev {
  left: 5px!important;
  transform: translate3d(0, -50%, 0);
  box-shadow: 1px 5px 10px rgba(0,0,0, 0.1);
}
.splide__arrow--next {
  right: 5px!important;
  transform: translate3d(0, -50%, 0);
  box-shadow: 1px 5px 10px rgba(0,0,0, 0.1);
}
.splide__arrow:disabled {
  opacity: 0!important;
}

.splide__arrow--next:not(:disabled) {
  animation: balance 1s linear infinite;
}
.splide__arrow--next:hover {
  animation: none;
}

@media (min-width: 900px) {
  .splide__arrow--prev {
    left: -20px!important;
  }
  .splide__arrow--next {
    right: -20px!important;
  }

  .sv-action.sv-action--hidden + #sv-nav-next {
    margin-left: auto;
    max-width: 100%;
  }
}

@keyframes balance {
  0% {
    transform: translate3d(0, -50%, 0);
  }
  25% {
    transform: translate3d(-3px, -50%, 0);
  }
  50% {
    transform: translate3d(0, -50%, 0);
  }
  75% {
    transform: translate3d(-3px, -50%, 0);
  }
  100% {
    transform: translate3d(0, -50%, 0);
  }
}

.splide__arrow--prev,
.splide__arrow--next {
  background-color: #ff4a11;
  background-image: none;
  opacity: 1;
  width: 28px;
  height: 28px;
}

.splide__arrow--prev svg,
.splide__arrow--next svg {
  fill: #fff;
  width: 16px;
  height: 16px;
}

.splide__pagination {
  bottom: -0.5px;
}
.splide__pagination__page {
  border: 1px solid var(--color-primary);
  opacity: 1;
}

.splide__pagination__page.is-active {
  background: var(--color-primary);
  opacity: 1
}


@media (max-height: 900px) {
  /* .testimonials {
    position: fixed;
    top: calc(100vh - 116px);
    background: #f1f1f1;
    left: 0;
    right: 0;
    padding: 10px 12px 50px ;
    transition: top .4s;
    box-shadow: 1px -4px 3px rgba(0,0,0,.07);
  }

  &.open {
    top: calc(100vh - 486px);
  } */
}

/* Progress Bar Percentage */
.sv-progressbar-percentage {
  position: absolute;
  top: -27px;
  right: 0;
  color: var(--color-primary);
  font-weight: bold;
  font-family: var(--font-family);
}